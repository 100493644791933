.grid-container {
    /*background-color: #fff;*/
    text-align: center;
    margin: auto;
    width: 100%;
    padding: 10px;
}

.container {
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;
}

.container * {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.border {
    border: 2px solid white;
    border-radius: 6px;
    overflow: hidden;
}

.background {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.height-one {
    width: 100%;
    padding-top: 50%;
    //min-height: 300px;
}

.height-two {
    width: 50%;
    padding-top: 50%;
}

.height-three {
    width: 33.3333%;
    padding-top: 33.3333%;
}

.video-one-half, .video-two-half {
    .video-cover, .video {
        height: calc(100vw / 4) !important;
    }
}

.video-one {
    height: 300px;
    padding: 0 !important;
}

.video-two {
    width: 50%;
    padding: 0 !important;
    @media screen and (max-width: 767px) {
        .video-cover, .video {
            height: calc(100vw / 2) !important;
        }
    }
    .video-cover, .video {
        height: 300px !important;
    }
}

.video-three {
    width: 33%;
    padding: 0 !important;
    .video-cover, .video {
        height: calc(100vw / 3) !important;
    }
}

.main-img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover !important;
    top: 0;
    left: 0;
}

.img-blur {
    filter: blur(10px);
}

.cover {
    display: none;
}

/*.cover {*/
/*    background-color: #222;*/
/*    opacity: 0.8;*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 0;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    border-radius: 6px;*/
/*}*/

.cover-text {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.3);
    margin-left: -16px !important; // Not sure why we need this in production... bundle splitting causing it?
}

/*.cover-text {*/
/*    right: 0;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    color: white;*/
/*    font-size: 7%;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    -webkit-transform: translate(0%, -50%);*/
/*    -ms-transform: translate(0%, -50%);*/
/*    transform: translate(0%, -50%);*/
/*    text-align: center;*/
/*}*/

/*.cover-text > p {*/
/*    margin: 0;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
.cover-text p {
    height: 100%;
    padding-top: calc(50% - 25px);
    color: #dfdfdf;
}

.cover-text.visible {
    display: block;
}

.slide {
    height: 0;
    bottom: 100%;
    transition: .5s ease;
    overflow: hidden;
    font-size: 3%;
}

.border:hover .slide {
    bottom: 0;
    height: auto;
}

.border:hover .animate-text {
    top: 62%
}

.circle-chip .MuiChip-label {
    padding: 8px;
}
